.payment-page-container {
    display: flex;
    flex-direction: row;
    align-items: start; 
    justify-content: center; 
}

.payment-page {
    flex: 1;
    margin-right:20px;
    max-width: 600px;
    padding: 20px;
    font-family: Helvetica, sans-serif;
}

.quotation-box-container {
    display: flex;
    flex-direction: column; 
    align-items: flex-start; 
    padding: 20px;
    margin-left:20px;
}

.quotation-box {
    width: 300px;
    height: 300px;
    padding: 20px;
    background-color: #f9f9f9;
    border: 3px solid #ddd;
    border-radius: 25px;
}

  
.info-box {
    background-color: #f9f9f9;
    border: 3px solid #ddd;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 25px;
}
  
.payment-page input[type="text"],
.payment-page input[type="email"],
.payment-page input[type="checkbox"] {
    width: calc(100% - 22px); /* Account for padding and border */
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
  
.payment-page input[type="checkbox"] {
    width: auto;
    margin-top: 0;
}
  
.payment-page button[type="submit"] {
    background-color: #4CAF50;
    color: white;
    padding: 15px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    display: block;
    margin-top: 20px;
}
  
.payment-page button[type="submit"]:hover {
    background-color: #45a049;
}

input::placeholder {
    font-family: Helvetica;
    font-style: italic;
    font-weight: bold;
    color: #999999; /* You can also change the color if you like */
}

.label {
    display: block;
    margin-top: 10px;
    font-family: Helvetica, sans-serif;
}
  