.login-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.login-form input {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-form button {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
}

.login-form button:hover {
    background-color: #0056b3;
}

.register-link {
    margin-top: 10px;
    text-align: center;
}

.register-link a {
    color: #28a745; /* Green color similar to Bootstrap's success color */
    text-decoration: none;
}

.register-link a:hover {
    text-decoration: underline;
}
