.Admin {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.Admin h1, .Admin h2 {
    color: #333;
}

.Admin table {
    width: 100%;
    border-collapse: collapse;
}

.Admin th, .Admin td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
}

.Admin th {
    background-color: #f8f8f8;
}

.Admin button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
}

.Admin button:hover {
    background-color: #0056b3;
}