.RequestTable {
    display: flex;
    width: 450px;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    border-radius: 24px;
    border: 1px solid var(--neutrals-8, #FCFCFD);
    background: #FFF;

    /* glass/01 */
    box-shadow: 0px 40px 64px -32px rgba(15, 15, 15, 0.10);
    backdrop-filter: blur(16px);
    /* border: 1px solid black; */
}

.ChoiceButton {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.InactiveButton {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    border-radius: 90px;
    background-color: var(--neutrals-6, #fafafa);
    border: 2px solid var(--neutrals-6, #E6E8EC);

    color: var(--neutrals-2, #23262F);
    text-align: center;

    /* Tiny/Normal/Bold */
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 133.333% */
}
.InactiveButton:hover {
    /* background-color: var(--neutrals-6, #fafafa); */
    cursor:pointer;
}
.InactiveButton:active {
    background-color: var(--neutrals-6, #E6E8EC);
    cursor:pointer;
}

.ActiveButton {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    border-radius: 90px;
    background: var(--neutrals-2, #23262F);
    border: 2px solid var(--neutrals-2, #23262F);
    color: var(--primary-white, #FFF);
    text-align: center;

    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 133.333% */
}
.ActiveButton:hover {
    background-color: var(--neutrals-2, #111218);
    cursor:pointer;
}
.ActiveButton:active {
    background-color: var(--neutrals-2, #23262F);
}

.InformationTable {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
}

.InformationFields {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    /* border: 1px solid black; */

}

.InputBox {
    display: flex;
    padding: 20px 20px 0px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    padding-bottom: 12px;
    border-bottom: 1px solid #F2F4F5;
    /* border: 1px solid black; */
}

.InputField {
    display: flex;
    align-items: center;
    gap: 12px;
    /* border: 1px solid black; */
}

.FromAddressText {
    display: flex;
    /* width: 174px; */
    flex-direction: column;
    align-items: flex-start;
    /* border: 1px solid black; */
}

.WeigthText {
    display: block;
    width: 174px;
    flex-direction: column;
    align-items: flex-start;
    /* border: 2px solid black; */
}

.WeigthText input {
    color: var(--neutrals-2, #23262F);
    font-size: 18px;
    font-weight: 700;
    border-style: none;
    width: 60px;
}

.UnitLabel {
    color: var(--sky-dark, #979C9E);
}

.FromAddressText input {
    color: var(--neutrals-2, #23262F);
    font-size: 18px;
    font-weight: 700;
    border-style: none;
    width: 362px;
}

input:focus{
    outline: none;
}

.SizeButtons{
    display: flex;
    gap: 16px;
}
.ActiveSizeButton {
    color: var(--Red, #CB0E16);
    font-size: 16px;
    font-weight: 750;
    line-height: 18px;
    word-wrap: break-word;
    background-color: transparent;
    border: none;
}

.SizeButton {
    color: #23262F;
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    word-wrap: break-word;
    background-color: transparent;
    border: none;
}
.ActiveSizeButton:hover, .SizeButton:hover{
    cursor:pointer;
}

.SizeLabel {
    color: var(--sky-dark, #979C9E);
}

.MetricTable {
    display: flex;
    width: 382px;
    align-items: flex-start;
    gap: 24px;
}

.DistanceTable {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.DistanceLabel {
    color: var(--sky-dark, #979C9E);

    /* Regular/Normal/Medium */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

.Distance {
    color: var(--Dark, #23262F);
    text-align: center;
    
    /* /Title3 */
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
}

.ArriveTable {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 8px;
    flex: 1 0 0;
}

.ArriveLabel {
    color: var(--sky-dark, #979C9E);

    /* Regular/Normal/Medium */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
}

.ArriveDate  {
    color: var(--Dark, #23262F);
    text-align: center;
    
    /* /Title3 */
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
}

.SubmitButton {
    display: flex;
    width: 336px;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 90px;
    border: 1px solid var(--Red, #CB0E16);
    background: var(--Red, #CB0E16);
    color: var(--primary-white, #FFF);
    text-align: center;
    /* Regular/Normal/Bold */
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
}

.SubmitButton:hover {
    background-color: var(--Red, #ec101b);
    cursor: pointer;
}

.SubmitButton:active {
    background: var(--Red, #CB0E16);
}

.a {
    background-color: black;
}