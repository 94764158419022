.live-chat {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}
  
.chat-toggle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #007bff;
    color: #fff;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}
  
.chat-toggle:hover {
    background-color: #0056b3;
}
  
.chat-window {
    position: fixed;
    bottom: 90px;
    right: 20px;
    width: 90%;
    max-width: 800px;
    height: 80%;
    max-height: 600px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: height 0.3s ease;
}

.chat-header {
    padding: 10px 16px;
    background: #007bff;
    color: white;
    border-top-left-radius: 8px; /* Match chat-window border radius */
    border-top-right-radius: 8px;
}

.chat-header h2 {
    margin: 0;
    font-size: 1.25rem;
}

.chat-status {
    font-size: 0.8rem;
    color: #efefef;
}
  
.messages {
    flex: 1;
    padding: 16px;
    overflow-y: auto;
}
  
input[type="text"] {
    width: calc(100% - 32px);
    margin: 0 16px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    margin-bottom: 16px;
    box-sizing: border-box;
}
  
.user-message {
    background-color: #f0f0f0;
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 4px;
}
  
.bot-message {
    background-color: #d9edf7;
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 4px;
}
  
input[type="text"] {
    width: calc(100% - 32px);
    margin: 0 16px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    margin-bottom: 16px;
}
  
input[type="text"]:focus {
    border-color: #007bff;
}

input.message-input::placeholder {
    color: #ccc;
}