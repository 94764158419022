.HomePage {
  align-items: center;
}
.HomeBox{
  width: 80%;
  margin: 16px;
  height: 770px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background-image: url("../../assets/HomeWallpaper.jpg");
  border-radius: 24px;
  background-size: 1540px 770px;
  flex-shrink: 0;
}

.overlay {
  position: absolute;
  top: 19.5%;
  right: 10%;
  padding: 40px;
  /* display: flex; */
  /* align-items: center; */
}