.Header {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  display: inline-flex;
}
.combar {
  border-bottom: 0.25px solid #e4e4e4;
  padding: 10px 0px 10px 203px;
}

.navbar {
  padding: 24px 160px 24px 108px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.combar li {
  float: left;
}

.navbar-branch {
  float: left;
}

li a {
  display: block;
  text-align: center;
  padding: 0px 14px 0px 16px;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  color: black;
}

.combar li a:hover {
  color: black;
}

.combar a:visited {
  color: black;
}

.combar a:active {
  color: black;
}

.navbar .left {
  display: inline-block;

}

.navbar .right {
  float: right;  

}
.review-item {
  height: 100%;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 16px;
  padding-bottom: 16px;
  background: #23262F;
  border-radius: 90px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  display: inline-flex;
}

.review-link {
  color: #FCFCFD;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  word-wrap: break-word
}

.contact-item {
  height: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: 24px;
  margin-right: 24px;
  background: #FCFCFD;
  border-radius: 90px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  border: 1px #E3E5E5 solid;
}

.contact-link {
  color: #23262F;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  word-wrap: break-word
}

.profile-item {
  height: 100%;
  padding-top: 9px;
  padding-bottom: 14px;
  background: #E3E5E5;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  gap: 12px;
  display: inline-flex;
}

.profile-link {
  color: #23262F;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  word-wrap: break-word
}