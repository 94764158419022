.Track {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.Track h1, .Track h2 {
  color: #333;
}

.Track form {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  width: 100%;
}

.Track input {
  flex-grow: 1;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: 400px;
}

.Track-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007BFF;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.Track-button:hover, .Track-button:focus {
  background-color: #0056b3;
}

.Track-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.Track ul {
  list-style-type: none;
  padding: 0;
}

.Track li {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.Track li:last-child {
  border-bottom: none;
}

.track-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}