.UserDashboard {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    color: #007BFF;
  }
  
  h2 {
    margin-top: 20px;
    color: #28a745; /* Green color similar to Bootstrap's success color */
  }
  
  table {
    width: 100%;
    margin-top: 10px;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }
  
  th {
    background-color: #f8f9fa; /* Light gray background color */
  }
  
  p {
    margin-bottom: 10px;
  }
  
  .LiveChat {
    margin-top: 20px;
    text-align: center;
    color: #007BFF;
  }
  